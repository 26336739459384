import { Component } from '@angular/core';
import { CrowdWalletService } from '../../services';
import { IGetAddressReqMdl, IUserDetail } from '../../models';
import { FormControl, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-password-modal',
  templateUrl: './password-modal.component.html',
  styleUrl: './password-modal.component.scss'
})
export class PasswordModalComponent {
  public userDetail!: IUserDetail;
  public passwordVisible = false;
  public loading = false;
  public password = new FormControl('', [
    Validators.required,
    Validators.pattern(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!_@#$%^&*()-]).{8,}$/
    )
  ]);

  constructor(
    private connectWalletService: CrowdWalletService,
    private nzModalRef: NzModalRef
  ) {
    this.userDetail = this.connectWalletService.userDetail;
  }

  async checkPassword() {
    this.password.markAsDirty();
    this.password.markAsTouched();
    this.password.updateValueAndValidity();

    if (this.password.invalid) {
      return;
    }

    this.loading = true;

    const confirmed = await this.confirmSmartWallet();

    if (!confirmed) {
      this.password?.setErrors({ pattern: true });
      this.loading = false;
      return;
    }

    this.loading = false;
    this.destroyModal(true);
  }

  async confirmSmartWallet(): Promise<string | undefined> {
    try {
      const data: IGetAddressReqMdl = {
        email: this.userDetail.email ?? '',
        password: this.password.value ?? ''
      };

      return await this.connectWalletService.signInAndGetAddress(data, true);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  destroyModal(result?: boolean) {
    this.nzModalRef.destroy(result);
  }
}
