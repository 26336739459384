import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";
import { AmatesCrowdToken, IExecuteTransfer, IGetAddressReqMdl, ISignInDto, IUserDetail } from "../models";
import { StorageService } from "./storage.service";
import { Web3Service } from "./web3.service";
import { NetworksService } from "./networks.service";
import { Router } from "@angular/router";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { formatNumber, NumberType } from '@uniswap/conedison/format.js';
import { Conversion } from "@crowdswap/constant";

@Injectable()
export class CrowdWalletService extends Web3Service {
  public SEARCH_NOTIFIER_TIME = 1000;
  private STORAGE_KEY: string = 'crowdWalletData';

  // public userData = new UserDetail();
  private static userPassword: string;

  constructor(
    private readonly http: HttpClient,
    private readonly storage: StorageService,
    private readonly router: Router,
    protected networksService: NetworksService,
    private readonly nzNotificationService: NzNotificationService
  ) {
    super(networksService);
    this.initProvider(this.userDetail.address);
  }

  get userDetail(): IUserDetail {
    const data = this.storage.getItemFromLocalStorage('user');
    return data ? JSON.parse(data) : {};
  }

  set userDetail(data: IUserDetail) {
    const currentData = this.userDetail;
    const updatedData = { ...currentData, ...data };
    this.storage.setItemToLocalStorage('user', updatedData);
  }

  get userPassword(): string {
    return CrowdWalletService.userPassword;
  }

  set userPassword(password: string) {
    CrowdWalletService.userPassword = password;
  }

  async signIn(data : IGetAddressReqMdl , headers : HttpHeaders) : Promise <ISignInDto> {
    let signInResult : ISignInDto = {status : true , statusCode : 200};
    try {
    const url = [environment.WalletUrl, 'auth/sign-in'].join('/');
    const response =  (await this.http.post(url, data ,{ headers : headers }).toPromise()) as {
      address: string;
      access_token: string;
      twoFactorAuthenticationEnabled: boolean;
      otpGuardedActions: string[];
    };
    signInResult.result = response;
    this.userDetail = response!;

      return signInResult;
    }catch (e) {
      if (e instanceof HttpErrorResponse) {
        // Handle specific error codes
        if (e.status === 401) {
          // Wrong password
          signInResult = { status: false, statusCode: 401 };
        } else if (e.status === 400) {
          // OTP is wrong
          signInResult = { status: false, statusCode: 400 };
        } else {
          // Other status codes
          signInResult = { status: false, statusCode: e.status };
        }
      } else {
        console.error('An unknown error occurred:', e);
        signInResult = { status: false, statusCode: 500 }; // Fallback for unexpected errors
      }
      return signInResult;
    }
  }

  public async signInAndGetAddress(
    data: IGetAddressReqMdl,
    updatePass?: boolean
  ): Promise<string | undefined> {

    try {
      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-otp-token': data.otp || '-'
      });

      const signInDto = await this.signIn(data , headers);

      if (signInDto.status) {
        if (!updatePass) {
          this.initProvider(signInDto.result?.address);
        }

        this.userPassword = data.password;

        return signInDto.result?.address;
      } else {
        return undefined;
      }
    } catch (err: any) {
      console.error(`Cannot fetch user address`);
      return undefined;
    }
  }

  public async isUserExistOrSendCode(emailAddress: string): Promise<boolean> {
    try {
      const url = [
        environment.WalletUrl,
        'auth/send-verification-code'
      ].join('/');
      const data = { email: emailAddress, purpose: 'amates-register' };
      this.userDetail = { email: emailAddress };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: string;
      };

      return userObj.result !== 'OK';
    } catch (err: any) {
      if (
        err.error.statusCode === 302 &&
        err.error.message === 'User Already Exists'
      ) {
        return true;
      }
      console.error(`Cannot send verification code to the server`);
      throw err;
    }
  }

  public async initiatePasswordReset(emailAddress: string): Promise<boolean> {
    try {
      const url = [
        environment.WalletUrl,
        'auth/initiate-password-reset'

      ].join('/');
      const data = { email: emailAddress };
      this.userDetail = { email: emailAddress };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: string;
      };

      return userObj.result === 'OK';
    } catch (err: any) {
      if (
        err.error.statusCode === 302 &&
        err.error.message === 'User Already Exists'
      ) {
        return true;
      }
      console.error(`Cannot send verification code to the server`);
      throw err;
    }
  }


  public async isVerificationCodeCorrect(
    emailAddress: string,
    verificationCode: string
  ) {
    try {
      const url = [
        environment.WalletUrl,
        'auth/is-verification-code-correct'
      ].join('/');
      const data = { email: emailAddress, verificationCode: verificationCode };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: boolean;
      };

      return userObj.result;
    } catch (err: any) {
      console.error(`Cannot send verification code to the server`);
      return false;
    }
  }

  public async registerUser(
    emailAddress: string,
    verificationCode: string,
    password: string
  ) {
    try {
      const url = [environment.WalletUrl, 'auth/register'].join('/');
      const data = {
        email: emailAddress,
        verificationCode: verificationCode,
        password: password
      };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: string;
      };

      return userObj.result === 'OK';
    } catch (err: any) {
      console.log(`Cannot send verification code to the server`);
      return false;
    }
  }

  public async resetPassword(
    emailAddress: string,
    verificationCode: string,
    password: string
  ) {
    try {
      const url = [environment.WalletUrl, 'auth/reset-password'].join('/');
      const data = {
        email: emailAddress,
        verificationCode: verificationCode,
        password: password
      };

      let userObj = (await this.http.post(url, data).toPromise()) as {
        result: string;
      };

      return userObj.result === 'OK';
    } catch (err: any) {
      console.log(`Cannot send verification code to the server`);
      return false;
    }
  }

  public async enable2FA(
    password: string | null,
    secret2FA: string,
    otp: string
  ): Promise<void> {
    const url = [environment.WalletUrl, 'auth/enable-2fa'].join('/');
    const data = {
      email: this.userDetail.email,
      password: password,
      secret2fa: secret2FA,
      otpCode: otp
    };

    await this.http.post(url, data).toPromise();
    this.userDetail = {
      twoFactorAuthenticationEnabled: true,
      otpGuardedActions: ['sign-in'],
      access_token:  this.userDetail.access_token
    };
  }

  public logOut() {
    this.storage.removeItemFromLocalStorage('user');
    this.userPassword = '';
    this.router.navigate(['/', 'login-register']);
  }

  public async executeTransfer(
    data: IExecuteTransfer,
    otp?: string
  ): Promise<{ hash: string } | undefined> {
    try {
      const url = [environment.WalletUrl, 'account/execute-transfer'].join(
        '/'
      );

      const transfer = await this.http
        .post<{ hash: string } | undefined>(url, data, this.setHeader(otp))
        .toPromise();

      if (!transfer) {
        throwError(transfer);
      }

      return transfer;
    } catch (error: any) {
      console.error('Transaction Failed:', error);
      this.nzNotificationService.error(
        'Transaction Failed:',
        'We encountered an issue while processing your transfer. Please check your account balance, ensure the recipient details are correct, and try again. If the issue persists, contact customer support for assistance.',
        {
          nzPlacement: 'bottomRight'
        }
      );
      return;
    }
  }

  setHeader(otp: string = '') {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + this.userDetail.access_token,
      'x-otp-token': otp
    });

    return { headers: headers };
  }

  public async sendVerificationCode(emailAddress: string, purpose: string) {
    const url = `${environment.WalletUrl}/auth/send-verification-code`;
    const data = { email: emailAddress, purpose: purpose };

    let userObj = (await this.http.post(url, data).toPromise()) as {
      result: string;
    };
    return userObj;
  }

  public async getTokens(): Promise<any[]> {
    const chainIds = environment.ACTIVE_NETWORK;

    // Array to store all token data
    const allTokens: any[] = [];

    // Iterate over all chain IDs
    for (const chainId of chainIds) {
      const url = `${environment.WalletUrl}/account/get-balance?chainId=${chainId}`;
      try {
        const userObj = (await this.http.get(url, this.setHeader()).toPromise()) as AmatesCrowdToken[];

        // Process tokens for this chain
        const tokensWithChainInfo = userObj.map(token => ({
          ...token,
          chainId,
          balanceToDisplay: formatNumber(
            parseFloat(
              Conversion.convertStringFromDecimal(
                token.balance.toString(),
                token.decimals
              )
            ),
            NumberType.TokenNonTx
          ),
          balance: Conversion.convertStringFromDecimal(
            token.balance.toString(),
            token.decimals
          ),
          value: (
            parseFloat(token.balance) * parseFloat(token.price ?? '0')
          ).toString(),
          balanceToDisplayInUSDT: formatNumber(
            parseFloat(
              Conversion.convertStringFromDecimal(
                token.balance.toString(),
                token.decimals
              )
            ) * parseFloat(token.price ?? '0'),
            NumberType.FiatTokenPrice
          ),
        })).filter( token => +token.balance > 0);

        // Add tokens from this chain to the overall list
        allTokens.push(...tokensWithChainInfo);

      } catch (error) {
        console.error(`Failed to fetch tokens for chainId: ${chainId}`, error);
      }
    }

    return allTokens;
  }

  public async change2FaSetting(
    otpGuardActions,
    twoFactorAuthenticationEnabled,
    verificationCode
  ) {
    const url = `${environment.WalletUrl}/auth/2fa-settings`;

    const data = {
      otpGuardedActions: otpGuardActions,
      twoFactorAuthenticationEnabled: twoFactorAuthenticationEnabled,
      verificationCode: verificationCode
    };

    let userObj = (await this.http
      .post(url, data, this.setHeader())
      .toPromise()) as {
      result: any;
    };
    return userObj.result;
  }

  public async reloadSetting() {
    let settings: any = await this.get2FaSettings();
    this.userDetail.otpGuardedActions = settings.otpGuardedActions;
    this.userDetail.twoFactorAuthenticationEnabled =
      settings.twoFactorAuthenticationEnabled;
    this.userDetail = {otpGuardedActions : settings.otpGuardedActions , twoFactorAuthenticationEnabled : settings.twoFactorAuthenticationEnabled};
  }

  public saveUserData() {
    this.storage.setItemToLocalStorage(this.STORAGE_KEY, this.userDetail);
  }

  public async get2FaSettings() {
    const url = `${environment.WalletUrl}/auth/2fa-settings`;

    return await this.http.get(url, this.setHeader()).toPromise();
  }

  public async is2FaSignInEnabled(email : string) {
    const url = `${environment.WalletUrl}/auth/need-otp-signin?email=${email}`;

    const response = await this.http.get(url).toPromise() as {
      result : boolean
    };
    return response.result;
  }
}
