export enum LoginIndex {
  email = 0,
  firstTimeOtp = 1,
  enterPassword = 2,
  setPassword = 3,
  reEnterPassword = 4,
  passwordChanged = 5,
  login = 6,
  welcome = 7,
  tokenTransfer = 8,
  checkTransfer = 9,
  enterOtpForLogin = 10
}

export interface IUserDetail {
  email?: string;
  address?: string;
  access_token?: string;
  otpGuardedActions?: string[];
  twoFactorAuthenticationEnabled?: boolean;
}

export class UserDetail implements IUserDetail {
  email = '';
  address = '';
  access_token = '';
  otpGuardedActions: string[] = [];
  twoFactorAuthenticationEnabled = false;
}
